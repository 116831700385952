export const __registerBlazorGoogleMapsAutocompleteHandler = function (dotnet) {
	window.agilitas.googleMaps.initialize(function ({ name: location, lat: latitude, lng: longitude }) {
		console.debug('invoking OnLocationAutoComplete', location, latitude, longitude);
		dotnet.invokeMethodAsync('OnLocationAutoComplete', location, latitude, longitude)
	});
};

export const __blazorRecaptcha = async function (token) {
	await new Promise((resolve) => {
		grecaptcha.ready(function () {
			resolve();
		});
	});

	return await grecaptcha.execute(token, { action: 'submit' });
}

export const searchLocation = function (inputId) {
	return new Promise((resolve, reject) => {
		const locationInputField = document.getElementById(inputId);

		const geocoder = new google.maps.Geocoder();

		geocoder.geocode({ 'address': locationInputField.value }, async (results, status) => {
			if (status == 'OK') {
				resolve({
					Longitude: results[0].geometry.location.lng(),
					Latitude: results[0].geometry.location.lat(),
					Name: results[0].address_components[0].long_name
				})
			}
			// Send empty location response in case location input was empty
			else if (status == 'INVALID_REQUEST') {
				resolve({
					Longitude: 0,
					Latitude: 0,
					Name: null
				});
			}
			else {
				reject(`${status}: No results found`);
			}
		})
	})
}

export function jobPostingViewEvent(jobData) {
	dataLayer.push({
		"event": "jobposting_view",
		"data": {
			"level_1": "joPosting",
			"level_2": "view",
			"level_3": `${jobData.jobPublicationId}`,
			"level_4": `${jobData.jobTitle}`,
			"level_5": `${jobData.jobContract}`,
			"level_6": `${jobData.jobFunction}`,
			"level_7": `${jobData.jobBranch}`,
			"level_8": `${jobData.jobMedium}`,
			"level_9": `${jobData.companyId}`
		}
	});
}

export function jobApplicationApplyEvent(jobData, enhancedConversions) {
	dataLayer.push({
		"event": `form_jobApplication_complete`,
		"data": {
			"level_1": "jobApplication",
			"level_2": "complete",
			"level_3": "jobApplicationForm",
			"level_4": `${jobData.jobPublicationId}`,
			"level_5": `${jobData.jobTitle}`,
			"level_6": `${jobData.jobContract}`,
			"level_7": `${jobData.jobFunction}`,
			"level_8": `${jobData.jobBranch}`,
			"level_9": `${jobData.jobMedium}`,
			"level_10": `${jobData.companyId}`,
			...enhancedConversions
		}
	});
}