﻿import { __registerBlazorGoogleMapsAutocompleteHandler, __blazorRecaptcha, jobApplicationApplyEvent, searchLocation, jobPostingViewEvent } from './frontend/blazor';

window.agilitas = window.agilitas || {};
window.agilitas.vacancies = {
	__registerBlazorGoogleMapsAutocompleteHandler,
	__blazorRecaptcha,
	jobApplicationApplyEvent,
	searchLocation,
	jobPostingViewEvent
};





